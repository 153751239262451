<template>
  <v-container>
    <v-row>
      <v-col>
        <v-select
          v-model="medico_id"
          :items="medicos"
          hide-details
          dense
          item-value="id"
          item-text="nombre"
          label="Médico"
        ></v-select>
      </v-col>
      <v-col>
        <v-autocomplete
          ref="pacientesSearchField"
          v-model="pacienteSelected"
          :items="pacientes"
          :loading="isLoadingPacientes"
          :search-input.sync="searchPacientes"
          label="Buscar Paciente"
          placeholder="Empieza a escribir para buscar"
          prepend-icon="mdi-database-search"
          :hide-no-data="pacientesHideNoData"
          no-data-text="No hay resultados"
          hide-details
          clearable
          return-object
          dense
          item-value="id"
          item-text="nombres"
          @change="setPaciente"
          @blur="searchPacientes = ''"
          @click:clear="clearSearchPacientes"
          no-filter
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{ [item.apellidos, item.nombres].join(', ') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="text--primary">
                  {{ item.doc_tipo_descripcion }} {{ item.doc_numero }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="text-right">
        <v-btn text color="primary" class="ml-4" title="Nuevo Paciente"
          aria-label="Nuevo Paciente" @click="addPaciente()">
          Nuevo Paciente
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-show="paciente.id">
      <v-col>
        <table style="width: 100%" class="elevation-1">
          <thead>
            <tr>
              <th class="subtitle-2 text-center">Apellidos</th>
              <th class="subtitle-2 text-center">Nombres</th>
              <th class="subtitle-2 text-center">Documento</th>
              <th class="subtitle-2 text-center">Edad</th>
              <th class="subtitle-2 text-center">Fecha de Nacimiento</th>
              <th class="subtitle-2 text-center">Obra Social</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="body-2 text-center">{{ paciente.apellidos }}</td>
              <td class="body-2 text-center">{{ paciente.nombres }}</td>
              <td class="body-2 text-center">{{ paciente.documento }}</td>
              <td class="body-2 text-center">{{ paciente.edad }}</td>
              <td class="body-2 text-center">{{ paciente.fecha_nacimiento }}</td>
              <td class="body-2 text-center">{{ paciente.obra_social }}</td>
              <td class="body-2 text-right">
                <v-btn icon title="Editar Datos del Paciente"
                  aria-label="Editar Datos del Paciente"
                  @click="editPaciente()">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>

    <v-row v-show="paciente.id">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :options.sync="options"
          :loading="loading"
          item-key="id"
          :expanded.sync="expanded"
          show-expand
          class="elevation-1"
          @item-expanded="onItemExpanded"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Historia Clinica</v-toolbar-title>

              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>

              <v-spacer></v-spacer>

              <v-dialog v-model="dialog" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark class="mb-2" v-on="on" v-show="paciente.id">
                    Nuevo
                  </v-btn>
                </template>
                <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
                  <v-form @submit.prevent="passes(save)">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Agregar Registro</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col>
                              <ValidationProvider vid="titulo" name="Título" rules="required"
                                v-slot="{ errors, valid }">
                                <v-text-field
                                  v-model="item.titulo"
                                  label="Título"
                                  filled
                                  dense
                                  required
                                  :error-messages="errors"
                                  :success="valid"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <ValidationProvider vid="detalle" name="Detalle" rules="required"
                                v-slot="{ errors, valid }">
                                <v-textarea
                                  ref="detalle"
                                  v-model="item.detalle"
                                  label="Detalle"
                                  filled
                                  dense
                                  auto-grow
                                  :error-messages="errors"
                                  :success="valid"
                                ></v-textarea>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <ValidationProvider
                                vid="fotos"
                                name="Fotos"
                                rules=""
                                v-slot="{ errors, valid }"
                              >
                                <v-file-input
                                  v-model="item.fotos"
                                  multiple
                                  accept="image/*"
                                  prepend-icon="mdi-image-outline"
                                  label="Fotos"
                                  :error-messages="errors"
                                  :success="valid"
                                ></v-file-input>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" @click.prevent="passes(save)"
                          color="primary" dark
                          :loading="saving">guardar</v-btn>
                        <v-btn text :disabled="saving" class="mr-4" @click.stop="close">
                          cancelar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </ValidationObserver>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-4">
              <v-progress-circular
                v-if="item.isLoading"
                :size="70"
                :width="7"
                indeterminate
                color="primary"
              ></v-progress-circular>

              <div style="white-space: pre-wrap;">{{ item.detalle }}</div>

              <v-row v-if="item.fotos" class="mt-4">
                <v-col
                  v-for="foto in item.fotos"
                  :key="foto.id"
                  class="d-flex child-flex"
                  cols="4"
                  sm="1"
                >
                  <a :href="foto.url" target="_blank">
                    <v-img
                      :src="foto.url"
                      :alt="foto.nombre"
                      aspect-ratio="1"
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </a>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogPacienteForm" persistent fullscreen>
      <PacienteForm
        :active="dialogPacienteForm"
        :paciente="pacienteFormData"
        @dismiss="closePacienteForm"
      />
    </v-dialog>
  </v-container>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"

import _ from 'lodash'
import moment from 'moment'

import PacienteForm from '../components/PacienteForm'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    PacienteForm
  },

  name: 'HistoriasClinicas',

  data: () => ({
    saving: false,
    loading: false,
    dialog: false,
    headers: [
      { text: 'Título', value: 'titulo', sortable: false },
      { text: 'Fecha', value: 'created_at', align: 'right', sortable: false },
      { text: '', value: 'data-table-expand' },
    ],
    expanded: [],
    items: [],
    total: 0,
    options: {},
    medicos: [],
    medico_id: null,
    item: {
      medico_id: null,
      paciente_id: null,
      titulo: '',
      detalle: '',
      fotos: [],
    },
    defaultItem: {
      medico_id: null,
      paciente_id: null,
      titulo: '',
      detalle: '',
      fotos: [],
    },
    paciente: {},
    pacientes: [],
    pacienteSelected: null,
    isLoadingPacientes: false,
    loadingPaciente: false,
    searchPacientes: '',
    pacientesHideNoData: true,
    savingPaciente: false,
    dialogPacienteForm: false,
    pacienteFormData: {},
  }),

  watch: {
    options: {
      handler () {
        this.query()
      },
      deep: true,
    },

    searchPacientes (val) {
      if (val && val.length > 1) {
        this.pacientesHideNoData = false
        //this.debouncePacientes(val)
        this.fetchPacientes(val);
      } else {
        this.pacientesHideNoData = true
        this.pacientes = [];
      }
    },
  },

  methods: {
    formatDate (value) {
      return moment(value).format('DD/MM/YYYY')
    },

    query () {
      this.loading = true;

      let filters = {
        medico_id: this.medico_id,
        paciente_id: this.paciente.id,
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: this.options.itemsPerPage,
        page: this.options.page,
      };

      this.$http.get('historias-clinicas', {params: filters})
      .then((response) => {
        this.items = response.data
        this.total = parseInt(response.headers['pager-total'])
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false;
      })
    },

    save () {
      this.saving = true

      const formData = new FormData()

      formData.append('titulo', this.item.titulo)
      formData.append('detalle', this.item.detalle)
      formData.append('paciente_id', this.paciente.id)
      formData.append('medico_id', this.medico_id)

      for (var i = this.item.fotos.length - 1; i >= 0; i--) {
        if (this.item.fotos[i] instanceof File) {
          formData.append('fotos[]', this.item.fotos[i])
        }
      }

      this.$http.post('historias-clinicas', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.$eventHub.$emit('snackbar-message', 'El registro fue creado!', 'success')
          this.close(response.data)
          this.query()
        })
        .catch((error) => {
          switch (error.response.status) {
            case 422: {
              const errors = error.response.data.errors || false

              if (errors) {
                // Procesar errores en las fotos
                if (!errors.fotos) {
                  for (const err in errors) {
                    if (err.startsWith('fotos.')) {
                      const index = parseInt(err.split('.')[1])

                      if (!Array.isArray(errors.fotos)) {
                        errors.fotos = []
                      }

                      errors.fotos.push(`Foto #${index + 1}: ${errors[err]}`)
                    }
                  }
                }

                // Agregar errores al formulario
                this.$refs.obs.setErrors(errors);
              }

              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')

              break;
            }

            case 401:
              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    resetForm: function () {
      this.$refs.obs.reset()
      this.item = {...this.defaultItem}
    },

    close: function () {
      this.resetForm()
      this.dialog = false
    },

    setPaciente (selected) {
      if (selected) {
        this.paciente.id = selected.id
        this.paciente.apellidos = selected.apellidos
        this.paciente.nombres = selected.nombres
        this.paciente.documento = selected.doc_tipo_descripcion+' '+selected.doc_numero
        this.paciente.fecha_nacimiento = selected.fecha_nacimiento_fmt
        this.paciente.edad = selected.edad
        this.paciente.obra_social = selected.obra_social_nombre

        this.$refs.pacientesSearchField.blur()
        this.query();
      }

      this.$nextTick(() => {
        this.clearSearchPacientes()
      })
    },

    clearSearchPacientes () {
      this.pacienteSelected = null
      this.pacientes = []
    },

    debouncePacientes: _.debounce(function (val) {
      this.fetchPacientes(val)
    }, 1000),

    fetchPacientes(search = '') {
      this.isLoadingPacientes = true;

      let filters = {
        filter: search,
        rowsPerPage: 10,
        page: 1,
      };

      this.$http.get('pacientes', {params: filters})
      .then((response) => {
        this.pacientes = response.data
      })
      .catch((error) => {
        this.$eventHub.$emit('http-error', error)
      })
      .then(() => {
        this.isLoadingPacientes = false;
      })
    },

    closePacienteForm (item) {
      if (item) {
        // Procesar respuesta
        if (item.data.id === this.paciente.id) {
          // Actualizar datos
          this.paciente.apellidos = item.data.apellidos
          this.paciente.nombres = item.data.nombres
          this.paciente.documento = item.data.doc_tipo_descripcion+' '+item.data.doc_numero
          this.paciente.fecha_nacimiento = item.data.fecha_nacimiento_fmt
          this.paciente.edad = item.data.edad
          this.paciente.obra_social = item.data.obra_social_nombre
        } else {
          // Seleccionar nuevo cliente
          this.setPaciente(item.data)
        }
      }
      this.pacienteFormData = {}
      this.dialogPacienteForm = false
    },

    addPaciente () {
      this.pacienteFormData = {
        doc_tipo_id: 96,
        doc_numero: '',
        apellidos: '',
        nombres: '',
        fecha_nacimiento: null,
        telefono_fijo: '',
        telefono_movil: '',
        email: '',
        domicilio: '',
        localidad: '',
        provincia: '',
        pais: '',
        obra_social_id: null,
      }
      this.dialogPacienteForm = true
    },

    editPaciente () {
      this.loadingPaciente = true

      this.$http.get('pacientes/'+this.paciente.id)
        .then((response) => {
          this.pacienteFormData = response.data
          this.dialogPacienteForm = true
        })
        .catch(() => {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        })
        .finally(() => {
          this.loadingPaciente = false
        })
    },

    fetchMedicos: function () {
      this.$http.get('medicos')
      .then((response) => {
        this.medicos = response.data
        this.medico_id = response.data[0].id || null
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
    },

    onItemExpanded: function ({item, value}) {
      if (value === false) {
        return
      }

      if (item.detalle && item.fotos) {
        return
      }

      this.loading = true
      item.isLoading = true

      this.$http.get('historias-clinicas/'+item.id)
        .then((response) => {
          item.detalle = response.data.detalle
          item.fotos = response.data.fotos
        })
        .catch(() => {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        })
        .finally(() => {
          this.loading = false
          item.isLoading = false
        })
    }
  },

  created () {
    this.fetchMedicos()
  }
};
</script>
