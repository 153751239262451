<template>
  <v-card>
    <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
      <v-form @submit.prevent="passes(save)">
        <v-card-title>
          <span class="headline">Paciente</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <ValidationProvider vid="doc_tipo_id" name="Tipo Documento" rules="required" v-slot="{ errors, valid }">
                <v-select
                  ref="paciente_doc_tipo_id"
                  v-model="paciente.doc_tipo_id"
                  :items="tiposDocumento"
                  item-value="id"
                  item-text="descripcion"
                  filled
                  dense
                  label="Tipo Documento"
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-select>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="doc_numero" name="Número de Documento" rules="required" v-slot="{ errors, valid }">
                <v-text-field
                  v-model="paciente.doc_numero"
                  label="Número de Documento"
                  filled
                  dense
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="apellidos" name="Apellidos" rules="required" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.apellidos"
                  label="Apellidos"
                  dense
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="nombres" name="Nombres" rules="required" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.nombres"
                  label="Nombres"
                  dense
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-menu
                ref="datepickerFechaNacimiento"
                v-model="datepickerFechaNacimiento"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{}">
                  <ValidationProvider vid="fecha_nacimiento" name="Fecha Nacimiento" rules="" v-slot="{ errors, valid }">
                    <v-text-field
                      type="date"
                      ref="fechaNacimiento"
                      v-model="paciente.fecha_nacimiento"
                      filled
                      dense
                      label="Fecha Nacimiento"
                      prepend-icon="mdi-calendar"
                      :error-messages="errors"
                      :success="valid"
                      v-on:click:prepend="openDatepickerFechaNacimiento"
                      v-on:keydown.enter.stop.prevent="openDatepickerFechaNacimiento"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="paciente.fecha_nacimiento"
                  scrollable
                  @change="closeDatepickerFechaNacimiento"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <ValidationProvider vid="email" name="E-mail" rules="email" v-slot="{ errors, valid }">
                <v-text-field
                  type="email"
                  filled
                  v-model="paciente.email"
                  label="E-mail"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="telefono_fijo" name="Teléfono Fijo" rules="" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.telefono_fijo"
                  label="Teléfono Fijo"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="telefono_movil" name="Teléfono Móvil" rules="" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.telefono_movil"
                  label="Teléfono Móvil"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <ValidationProvider vid="domicilio" name="Domicilio" rules="" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.domicilio"
                  label="Domicilio"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="localidad" name="Localidad" rules="" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.localidad"
                  label="Localidad"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="pais" name="País" rules="" v-slot="{ errors, valid }">
                <v-text-field
                  filled
                  v-model="paciente.pais"
                  label="País"
                  dense
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="obra_social_id" name="Obra Social" rules="required" v-slot="{ errors, valid }">
                <v-select
                  v-model="paciente.obra_social_id"
                  :items="obrasSociales"
                  item-value="id"
                  item-text="nombre"
                  filled
                  label="Obra Social"
                  dense
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary" dark
            @click.prevent="passes(save)"
            :loading="saving">guardar</v-btn>
          <v-btn text :disabled="saving" @click.stop="close()">
            cancelar
          </v-btn>
        </v-card-actions>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  name: 'PacienteForm',

  props: {
    paciente: Object,
  },

  data: () => ({
    saving: false,
    datepickerFechaNacimiento: false,
    tiposDocumento: [],
    obrasSociales: [],
  }),

  methods: {
    save: function () {
      this.saving = true

      if (typeof this.paciente.id === 'undefined') {
        this.create()
      } else {
        this.update()
      }
    },

    create: function () {
      this.$http.post('pacientes?r=fmt', this.paciente)
        .then((response) => {
          this.$eventHub.$emit('snackbar-message', 'El registro fue creado!', 'success')
          this.close(response.data)
        })
        .catch((error) => {
          switch (error.response.status) {
            case 400:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')

              this.$refs.obs.setErrors(error.response.data);

              break;
            case 401:
              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    update: function () {
      this.$http.put('pacientes/'+this.paciente.id+'?r=fmt', this.paciente)
        .then((response) => {
          this.$eventHub.$emit('snackbar-message', 'El registro fue actualizado!', 'success')
          this.close(response.data)
        })
        .catch((error) => {
          switch (error.response.status) {
            case 400:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')

              this.$refs.obs.setErrors(error.response.data);

              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    fetchTiposDocumentos () {
      this.$http.get('tipos-documento')
      .then((response) => {
        this.tiposDocumento = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
    },

    fetchObrasSociales () {
      this.$http.get('obras-sociales')
      .then((response) => {
        this.obrasSociales = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
    },

    openDatepickerFechaNacimiento () {
      this.datepickerFechaNacimiento = true
    },

    closeDatepickerFechaNacimiento () {
      this.datepickerFechaNacimiento = false

      setTimeout(() => {
        this.$refs.fechaNacimiento.$el.querySelector('input').focus()
      }, 10);
    },

    close (item = false) {
      this.$refs.obs.reset()
      this.$emit('dismiss', item)
    },
  },

  created () {
    this.fetchTiposDocumentos()
    this.fetchObrasSociales()
  },
}
</script>
